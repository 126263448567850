import { getNavigationLabels } from "./browser-basics";
import { provideContext } from "./context-provider";
import * as util from "./util";
import { cleanUpUrl } from "./util";
import { getBrowserId } from "./compute-ids";
import { parseAction, parseFeature } from "./feature";
import {
  batchMergeRequest,
  batchMiniActionRequest,
  canBatchRequests,
  mergeBatcher,
} from "./batching";
import { get as toggleEnabled } from "@otto-ec/global-resources/toggle";
import { isEmptyObject } from "@otto-ec/global-resources/core";
import { DataContainer, parseDataContainer } from "./datacontainer";
import {
  ActionPayload,
  EventPayload,
  FeaturePayload,
  MergePayload,
  MiniActionPayload,
  MovePayload,
  OnLateMergeStrategy,
} from "./request-typings";
import { MoveData, MoveSource } from "./move-typings";
import { Action, Feature, MiniAction } from "./feature-typing";
import { forceInitialPageImpression } from "./bct";

const log = util.moduleLogger.scope("request");

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export async function doPostRequest<Payload extends Record<string, unknown>>(
  url: string,
  payload: Payload,
): Promise<boolean> {
  if (toggleEnabled("tr_disable_bct_requests", false)) {
    return Promise.resolve(false);
  }
  const beaconResult = await sendAsBeaconRequest(url, payload);
  if (beaconResult) {
    return Promise.resolve(true);
  }

  log.warn("Unable to send tracking request via beacon api, retrying via XHR now.");

  const xhr = await window.fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: new Headers({ "content-type": "application/json; charset=utf-8" }),
  });
  if (xhr.status < 400) {
    return true;
  }

  throw new Error(
    `Unable to send tracking request: Beacon rejected and Ajax failed with ${xhr.status} ${xhr.statusText}`,
  );
}

async function sendAsBeaconRequest<Payload extends Record<string, unknown>>(
  url: string,
  payload: Payload,
) {
  try {
    return window.navigator.sendBeacon(url, JSON.stringify(payload));
  } catch (beaconError) {
    return false;
  }
}

export function buildMergePayload(
  browserId: string,
  labels: DataContainer,
  onLate?: OnLateMergeStrategy,
  features?: Feature[],
): MergePayload {
  const context = provideContext();
  const mergeValues = util.flattenAndTransformDataContainer(labels);
  const onLateStrategy = onLate === "discard" ? onLate : "default";
  if (typeof features != "undefined") {
    features = features.map(parseFeature);
  }
  return {
    browserId,
    mergeValues,
    onLate: onLateStrategy,
    url: context.currentPath,
    clientMergeId: context.currentScid,
    features: features,
  };
}

/**
 *
 *
 *
 *
 *
 *
 */
export async function createPage(url: string, dataContainer: DataContainer): Promise<string> {
  const cleanUrl = cleanUpUrl(url);
  const gateUrl = `/gate/webapi/v1/page/${cleanUrl}`;

  const xhr = await window.fetch(gateUrl, {
    method: "POST",
    headers: new Headers({
      "content-type": "application/json; charset=utf-8",
    }),
  });
  if (xhr.status < 400) {
    const pageMergeId: string = JSON.parse(await xhr.text()).mergeId;

    const context = provideContext();
    context.replaceWithPageMergeId(pageMergeId, url);

    forceInitialPageImpression(parseDataContainer(dataContainer));
    return pageMergeId;
  }
  throw new Error(`Unable to send tracking request: Failed with ${xhr.status} ${xhr.statusText}`);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function sendMergeToGate(
  dataContainer: DataContainer,
  onLate?: OnLateMergeStrategy,
  features?: Feature[],
): Promise<boolean> {
  const browserId = getBrowserId();
  if (!browserId) {
    return Promise.resolve(false);
  }

  const context = provideContext();

  const pageMergeId = context.currentPageMergeId;
  if (!pageMergeId) {
    return Promise.resolve(false);
  }

  const url = `/gate/webapi/v3/pis/${pageMergeId}/merges`;
  const labels = {
    ...parseDataContainer(dataContainer),
    ...getNavigationLabels("merge"),
  };
  const payload = buildMergePayload(browserId, labels, onLate, features);

  if (mergeBatcher.shouldBatch()) {
    const batchUrl = `/gate/webapi/v1/pis/${pageMergeId}/merge-batches`;
    batchMergeRequest(batchUrl, browserId, payload);
    return Promise.resolve(true);
  }

  return doPostRequest(url, payload);
}

export function buildMovePayload(
  browserId: string,
  source: MoveSource,
  labels: DataContainer,
  action?: Action,
): MovePayload {
  const context = provideContext();

  const additionalLabels = util.flattenAndTransformDataContainer(labels);

  const returnable = {
    browserId,
    additionalLabels,
    source,
    clientMergeId: context.currentScid,
    targetUrl: context.currentPath,
  };
  if (action) {
    return {
      ...returnable,
      action,
    };
  }
  return returnable;
}

/**
 *
 *
 *
 *
 *
 *
 */
export function sendMoveToGate(moveData: MoveData): Promise<boolean> {
  const browserId = getBrowserId();
  if (!browserId) {
    return Promise.resolve(false);
  }

  const context = provideContext();

  const pageMergeId = context.currentPageMergeId;
  if (!pageMergeId) {
    return Promise.resolve(false);
  }

  const url = `/gate/webapi/v2/pis/${pageMergeId}/move`;
  const payload = buildMovePayload(
    browserId,
    moveData.source,
    moveData.additionalLabels,
    moveData.action,
  );

  return doPostRequest(url, payload);
}

export function buildMiniActionsPayload(
  browserId: string,
  miniActions: Array<MiniAction>,
): MiniActionPayload {
  const context = provideContext();

  return {
    browserId,
    url: context.currentPath,
    updates: miniActions,
  };
}

/**
 *
 *
 *
 *
 *
 */
export function sendMiniActionsToGate(miniActions: Array<MiniAction>): Promise<boolean> {
  const browserId = getBrowserId();
  if (!browserId) {
    return Promise.resolve(false);
  }

  const context = provideContext();

  const basePageMergeId = context.basePageMergeId;

  if (!basePageMergeId) {
    return Promise.resolve(false);
  }

  const url = `/gate/webapi/v1/pis/${basePageMergeId}/mini-actions`;
  const payload = buildMiniActionsPayload(browserId, miniActions);

  if (canBatchRequests()) {
    batchMiniActionRequest(url, browserId, payload);
    return Promise.resolve(true);
  }

  return doPostRequest(url, payload);
}

export function buildEventPayload(
  browserId: string,
  labels: DataContainer,
  action?: Partial<Action>,
): EventPayload {
  const context = provideContext();

  const additionalLabels = util.flattenAndTransformDataContainer(labels);

  const payload = {
    browserId,
    labels: additionalLabels,
    url: context.currentPath,
  };

  if (action) {
    return {
      ...payload,
      action: parseAction(action),
    };
  }

  return payload;
}

export async function submitEvent(dataContainer: DataContainer, action?: Action): Promise<void> {
  const browserId = getBrowserId();
  if (!browserId) {
    return;
  }

  const labels = {
    ...parseDataContainer(dataContainer),
    ...getNavigationLabels(action ? "action" : "event"),
  };

  const url = `/gate/webapi/v1/event`;
  const payload = buildEventPayload(browserId, labels, action);
  await doPostRequest(url, payload);
}

export async function submitEventMerge(
  eventMergeId: string,
  dataContainer: DataContainer,
  action?: Action,
): Promise<void> {
  const browserId = getBrowserId();
  if (!browserId) {
    return;
  }

  const labels = {
    ...parseDataContainer(dataContainer),
    ...getNavigationLabels(action ? "action" : "eventMerge"),
  };

  const url = `/gate/webapi/v1/event/${eventMergeId}`;
  const payload = buildEventPayload(browserId, labels, action);
  await doPostRequest(url, payload);
}

async function mergeRequest(
  browserId: string,
  pageMergeId: string,
  dataContainer: DataContainer,
  onLate?: OnLateMergeStrategy,
  features?: Feature[],
): Promise<void> {
  if (!dataContainer || (isEmptyObject(dataContainer) && typeof features == "undefined")) {
    return;
  }

  const url = `/gate/webapi/v3/pis/${pageMergeId}/merges`;
  const labels = {
    ...parseDataContainer(dataContainer),
    ...getNavigationLabels("merge"),
  };
  const payload = buildMergePayload(browserId, labels, onLate, features);

  if (mergeBatcher.shouldBatch()) {
    const batchUrl = `/gate/webapi/v1/pis/${pageMergeId}/merge-batches`;
    batchMergeRequest(batchUrl, browserId, payload);
    return;
  }

  await doPostRequest(url, payload);
}

export async function submitMerge(
  dataContainer: DataContainer,
  features?: Feature[],
  onLate?: "default" | "discard",
): Promise<void> {
  const browserId = getBrowserId();
  if (!browserId) {
    return;
  }

  const context = provideContext();

  const pageMergeId = context.currentPageMergeId;
  if (!pageMergeId) {
    return;
  }
  const mergeProm = mergeRequest(browserId, pageMergeId, dataContainer, onLate, features);
  await Promise.all([mergeProm]);
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function sendEventToGate(
  dataContainer: DataContainer,
  eventMergeId?: string,
): Promise<boolean> {
  const browserId = getBrowserId();
  if (!browserId) {
    return Promise.resolve(false);
  }

  const urlSuffix = eventMergeId ? `/${eventMergeId}` : "";

  const messageType = eventMergeId ? "eventMerge" : "event";
  const url = `/gate/webapi/v1/event${urlSuffix}`;
  const labels = {
    ...parseDataContainer(dataContainer),
    ...getNavigationLabels(messageType),
  };

  const payload = buildEventPayload(browserId, labels);
  return doPostRequest(url, payload);
}

export function buildActionPayload(
  browserId: string,
  labels: DataContainer,
  action: Partial<Action>,
): ActionPayload {
  const context = provideContext();

  const additionalLabels = util.flattenAndTransformDataContainer(labels);

  const parsedAction = parseAction(action);

  return {
    browserId,
    url: context.currentPath,
    labels: additionalLabels,
    action: parsedAction,
  };
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
export function sendEventActionToGate(
  dataContainer: DataContainer,
  action: Action,
  mergeId?: string,
): Promise<boolean> {
  const browserId = getBrowserId();
  if (!browserId) {
    return Promise.resolve(false);
  }

  const labels = {
    ...parseDataContainer(dataContainer),
    ...getNavigationLabels("action"),
  };
  const urlSuffix = mergeId ? `/${mergeId}` : "";
  try {
    const url = `/gate/webapi/v1/event${urlSuffix}`;
    const payload = buildActionPayload(browserId, labels, action);
    return doPostRequest(url, payload);
  } catch (parseError) {
    return Promise.reject(parseError);
  }
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function sendEventMergeToTrackingServer(
  dataContainer: DataContainer,
  eventMergeId: string,
): Promise<boolean> {
  return sendEventToGate(dataContainer, eventMergeId);
}

export function buildFeaturesPayload(browserId: string, features: Array<Feature>): FeaturePayload {
  const context = provideContext();
  const featuresToTransfer = features.map(parseFeature);

  return {
    browserId,
    clientMergeId: context.currentScid,
    url: context.currentPath,
    features: featuresToTransfer,
  };
}
