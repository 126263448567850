import * as util from "./util";
import { sendMoveToGate } from "./request";
import { provideContext } from "./context-provider";
import { parseAction } from "./feature";
import { storage as createStorage } from "@otto-ec/global-resources/storage";
import { delegate } from "@otto-ec/global-resources/event";
import { DataContainer, parseDataContainer } from "./datacontainer";
import { MoveData } from "./move-typings";
import { Action } from "./feature-typing";
import { get as isToggleEnabled } from "@otto-ec/global-resources/toggle";

const ATTRIBUTE_NAME = "ts-move";
const CLASS_NAME = ATTRIBUTE_NAME;
const ATTRIBUTE_NAME_ACTION = "ts-action";
const STORAGE_KEY = "ts-movedata";

const storage = createStorage(window.localStorage);

/**
 *
 *
 */
export function isValidLinkElement(
  currentTarget: EventTarget | null,
  isTestEnv = window.__ENV === "test",
): currentTarget is Element & {
  href: string;
} {
  const el = currentTarget as Partial<HTMLAnchorElement> | null;

  /*                                                        */
  /*                                                                 */
  if (!el || el.nodeType !== el.ELEMENT_NODE) {
    return false;
  }

  const href = el.href ? el.href : resolvebase64Href(el);

  /*                                 */
  return !!href && (!util.isExternalLink(href) || isTestEnv);
}

function resolvebase64Href(el: Partial<HTMLAnchorElement>): string | undefined {
  if (!el || !el.getAttribute) {
    return;
  }
  const b64Href: string | null = el.getAttribute("base64-href");
  if (b64Href) {
    try {
      const decoded = atob(b64Href);
      const pseudoAnchor = document.createElement("a");
      pseudoAnchor.href = decoded; /*                                                   */
      return pseudoAnchor.href;
    } catch (e) {
      return undefined;
    }
  } else {
    return undefined;
  }
}

/**
 *
 *
 *
 */
function handleLinkClick(event: MouseEvent): void {
  if (event.button === 2) {
    /*                   */
    return;
  }

  if (!isValidLinkElement(event.currentTarget)) {
    /*                     */
    return;
  }

  const linkElement = event.currentTarget;
  const additionalLabels = util.getLinkData(linkElement, ATTRIBUTE_NAME);
  const action = util.getLinkData(linkElement, ATTRIBUTE_NAME_ACTION);

  submitMoveWithAction(
    additionalLabels ? parseDataContainer(additionalLabels) : {},
    action ? parseAction(action) : undefined,
  );
}

export default function init() {
  if (util.internals.TRACKING_moveInitialized) {
    return;
  }

  util.internals.TRACKING_moveInitialized = true;

  /**
 *
 *
 */
  for (const eventType of ["click", "auxclick"] as const) {
    if (eventType === "click" || isToggleEnabled("tr_handle_auxclick", false)) {
      delegate(window.document, eventType, `.${CLASS_NAME}`, handleLinkClick);
    }
  }
  submitStoredMove();
}

/**
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 */
export function submitMove(dataContainer: DataContainer): boolean {
  return submitMoveWithAction(dataContainer);
}

/**
 *
 *
 *
 *
 *
 *
 */
export function submitMoveWithAction(
  dataContainer: DataContainer,
  optionalAction?: Action,
): boolean {
  const context = provideContext();
  const pageMergeId = context.currentPageMergeId;
  if (!pageMergeId) {
    return false;
  }

  const source = {
    clientMergeId: context.currentScid,
    pageMergeId,
    url: context.currentPath,
    referrer: document.referrer,
  };

  const labels = parseDataContainer(dataContainer) || {};

  if (context.isLayerContext) {
    labels.ts_ContextLevel = [context.contextLevel.toString()];
  }

  const moveData: MoveData = {
    additionalLabels: labels,
    source,
  };

  if (optionalAction) {
    moveData.action = parseAction(optionalAction);
  }

  return store(moveData);
}

/**
 *
 *
 */
export function submitStoredMove(): void {
  /*                            */
  const move = pop();
  if (move) {
    sendMoveToGate(move);
  }
}

/**
 *
 *
 *
 */
function pop(): MoveData | null {
  try {
    const move = storage.getItem(STORAGE_KEY);

    storage.removeItem(STORAGE_KEY);

    return move === null ? null : (JSON.parse(move) as MoveData);
  } catch (e) {
    return null;
  }
}

/**
 *
 *
 *
 */
function store(dataContainer: MoveData): boolean {
  return storage.setItem(STORAGE_KEY, JSON.stringify(dataContainer));
}
